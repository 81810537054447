import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Join Us at the Bluenose 5K 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/no-choice.png"
          alt="Purple Cow Internet - Picking the right plan"
          /> 
            <br></br>
            <br></br>
            <h1>Why is there Only One Internet Provider in my New Apartment?</h1>
            <p>In short it often comes down to money and relationships. Let me explain. When you move into a new apartment building, you might find that the building owner encourages you to go with a specific internet service provider (ISP) or that there is only one choice. This preference is not just about service quality but is rooted in the process of how ISPs gain access to buildings and the financial agreements involved.</p>
            
            <h2>The Access Agreement</h2>
            <p>When a new building is planned, all ISPs need to get permission through an Access Agreement from the building owner to run their fiber lines during construction. The Canadian Radio-television and Telecommunications Commission (CRTC) requires that building owners provide reasonable access to ISPs. These requirements were established in policies, including <a href="https://crtc.gc.ca/eng/archive/2003/dt2003-45.htm">Telecom Decision CRTC 2003-45</a> and <a href="https://crtc.gc.ca/eng/archive/2021/2021-239.htm">Telecom Regulatory Policy CRTC 2021-239</a>. Even though there are policies requiring equal access, this is not always happening in practice. On February 22, 2023, Execulink Telecom Inc. filed an application with the CRTC, submitting that it had been denied timely access to three MDUs located in Ontario. The CRTC had to step in to ensure equal access was given. This is a victory for Execulink and the industry. However, this process is somewhat unregulated, and in Nova Scotia, no one has challenged building owners on this ruling. As a result, building owners can decide whether to allow all ISPs or just one to run their lines in the building.</p>
            
            <h2>The Role of Money</h2>
            <p>Why would a building owner only allow one ISP? It generally comes down to money and relationships. These relationships are structured in two ways.</p>
            <p>The first is through an additional agreement to the Access Agreement, called the Marketing Agreement. This agreement forms a partnership where the building owner encourages new tenants to signup with the ISP, often providing the new tenants' contact information to that ISP. The ISP then pays the building owner a percentage of the revenue, ranging from 0% to 9%, based on how many units in the building use their service. By allowing only one ISP, building owners can maximize this penetration rate and, consequently, their revenue share.</p>
            <p>The second method, which is not yet super popular in Nova Scotia but is gaining traction, is called a bulk deal. A bulk deal is an agreement between the ISP and the building owner where the building owner buys internet service for the entire building and then either includes it in the rent or charges tenants separately. Usually, these agreements are signed for five-year periods, effectively eliminating any other ISP from wiring the building, knowing they will not gain any customers until potentially after the five-year bulk deal is over.</p>
            <p>At the end of the day, it's hard to put the blame on building owners as they are in the business to make money, and with construction materials at record-high prices, they are looking for new ways to create revenue streams.</p>
            
            <h2>The Issues</h2>
            <p>This practice raises several issues:</p>
            <ul>
                <li><strong>Reduced Competition:</strong> Tenants are forced to go with a single ISP, reducing their choices.</li>
                <li><strong>Monopolistic Pricing:</strong> The sole ISP can charge higher prices due to a lack of competition.</li>
                <li><strong>Service Quality:</strong> With no alternatives, service quality can suffer as the ISP has no incentive to improve.</li>
            </ul>
            <p>This practice is becoming more common in many developments around Halifax Regional Municipality (HRM).</p>
            
            <h2>A Path Forward</h2>
            <p>The best path forward is to provide tenants with the most choice in ISPs. More and more, the ISP a tenant chooses is a personal choice based not just on the best deal or service but on whether they resonate with the ISP’s mission and goals. One potential solution is to have a single set of fiber lines run to all units from a central communication room. Any ISP could then access these lines, eliminating the need for multiple redundant lines to each unit. This setup would save costs for ISPs and provide tenants with a choice between several providers.</p>
            <p>By addressing these issues, we can ensure that tenants have the freedom to choose their ISP based on their preferences, promoting competition and fair pricing in the market.</p>
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
